<template>
  <div>
    <div class="news_banner">
      <img :src="banner[0].bannerImg" alt="" width="100%" />
    </div>
    <el-row :gutter="20" class="title">
      <el-col :span="16" :offset="0">
        <div style="overflow: hidden">
          <div class="gs-0"><!-- HYBRID VISION™融合视觉技术 -->{{$t("technology.HYBRID")}}</div>
          <div class="gs-1">
			<img src="../assets/images/righti.png" alt="" width="100%" />
            <div class="gs-3">{{ rhsjlist[0].articleTitle }}</div>
            <div class="gs-4" v-html="rhsjlist[0].articleContents">
              <!-- 传统图像传感器能提供色彩丰富、适应人眼的图像，其工作原理为按照固定帧率进行图像数据采集,无法进行连续记录,帧与帧之间数据缺失,单帧有效信息占比低,全局曝光模式限制了动态范围。对机器而言,其工作帧率低、功耗高、冗余数据量大、动态范围窄。 -->
            </div>
          </div>

          <div class="gs-2">
            <img src="../assets/images/lefti.png" alt="" width="100%" />
            <div class="gs-3">{{ rhsjlist[1].articleTitle }}</div>
            <div class="gs-4" v-html="rhsjlist[1].articleContents">
              <!-- 事件驱动型传感器模仿人类视网膜神经元工作原理,仅快速响应物体运动中产生光照变化(事件),输出事件流,可以连续、低功耗地感知场景变化,并能自动调整参数适应各种光照条件和光照快速变化。对机器而言，其工作帧率高、功耗低、冗余数据量大、动态范围宽。 -->
            </div>
          </div>
          <div class="center">
            <div class="biaog">
			<div>
				<div class="biaog-1 bj11"></div>
				<div class="biaog-1 bj111"><!-- 传统图像传感器 -->{{$t("technology.CMOS Image Sensor")}}</div>
				<div class="biaog-1 bj111"><!-- 事件驱动型传感器 -->{{$t("technology.Event-based Vision Sensor")}}</div>
			</div>
              <div class="biaog-1 tub-1 border-left border-right border-bottom">
                <img src="../assets/images/tubtb1.png" alt="" /><span
                  ><!-- 速度 -->{{$t('technology.Speed')}}</span
                >
              </div>
              <div class="biaog-1 border-right border-bottom">30~60 fps</div>
              <div class="biaog-1 border-right border-bottom">>500 fps</div>
              <div class="biaog-1 tub-1 border-left border-right border-bottom">
                <img src="../assets/images/tubtb2.png" alt="" /><span
                  ><!-- 功耗 -->{{$t('technology.Cost')}}</span
                >
              </div>
              <div class="biaog-1 border-right border-bottom">>100 mW</div>
              <div class="biaog-1 border-right border-bottom">~10 mW</div>
              <div class="biaog-1 tub-1 border-left border-right border-bottom">
                <img src="../assets/images/tubtb3.png" alt="" /><span
                  ><!-- 冗余数据量 -->{{$t('technology.Data Redundancy')}}</span
                >
              </div>
			  <div class="biaog-1 border-right border-bottom">10 MB/s</div>
              <div class="biaog-1 border-right border-bottom">40-180 KB/s</div>
              <div class="biaog-1 tub-1 border-left border-right border-bottom">
                <img src="../assets/images/tubtb4.png" alt="" /><span
                  ><!-- 动态范围 -->{{$t('technology.Dynamic Range')}}</span
                >
              </div>
              <div class="biaog-1 border-right border-bottom">60 dB</div>
              <div class="biaog-1 border-right border-bottom">>120 dB</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="rs" v-if="!showvideoof">
      <div class="rs3">
        <img class="rs3-img" src="../assets/images/1234.png" alt="" @click="showvideo()"/>
      </div>
      <div class="rs2"><!-- HYBRID VISION发明者 -->{{$t('index.Hybrid Vision')}}</div>
    </div>
	<div class="video" v-else>
		<video autoplay controls>
		  <source :src="bannersp[0].productVideo"  type="video/mp4">
		</video>
		<i class="el-icon-circle-close" id="videoclose" @click="function(){showvideoof=false}"></i>
	</div>
    <el-row :gutter="20" class="title">
      <el-col :span="16" :offset="0">
        <div v-for="item in jsjslist" :key="item.id">
          <div class="gs-0">{{ item.articleTitle }}</div>
          <div class="wz" v-html="item.articleContents">
            <!-- Hybrid
            VisionN融合视觉技术创造性地融合了图像传感和事件传感器技术，同一颗芯片可以同时输出高质量图像数据和事件流。事件功能模仿人眼视网膜神经元工作原理，以微秒级速度、全时域记录并输出光照变化;图像功能支持输出高质量图像，避免单一事件相机的应用局限，延续传统图像传感器优势
            保证全幅画质和影
            像细节;能广泛应用至手机、消费电子、安防、汽车等领域。 -->
          </div>
        </div>
        <div style="height: 100px"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // title: "技术", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "我是keyWords关键字",
      },
      {
        name: "description",
        content: "我是description描述",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "www.baidu.com",
      },
    ],
  },
  data() {
    return {
      index: 1,
      banner: [
        {
          bannerImg: "",
        },
      ],
	  bannersp:[],
      rhsjlist: [
        { articleTitle: "", articleContents: "" },
        { articleTitle: "", articleContents: "" },
      ],
      jsjslist: "",
	  showvideoof: false
    };
  },
  computed:{
	  language(){
	  		  return this.$store.state.Language
	  }
  },
  watch:{
	  language(newVal,oldVal){ //切换语言
      window.document.title = this.$t('technology.title')
	  		 this.getrhsjlist()
			 this.getjsjslist()
			 this.getBanner()
			 this.getBannersp()
	  }
  },
  mounted() {
    this.getBanner();
	this.getBannersp();
    this.getrhsjlist();
    this.getjsjslist();
  },
  created() {
    window.document.title = this.$t('technology.title')
  },
  methods: {
	  showvideo(){
		  console.log('触发事件')
		  this.showvideoof = true
	  },
    qh(index) {
      this.index = index;
      console.log(index);
    },
    getBanner() {
	let language = this.$store.state.Language
      this.$http.get("/appBanner/getBannerList?spaceId=9&language="+language).then((res) => {
		console.log(res)
        this.banner = res.data;
        console.log(this.banner);
      });
    },
	getBannersp(){
		let language = this.$store.state.Language
		  this.$http.get("/appBanner/getBannerList?spaceId=18&language="+language).then((res) => {
			console.log(res)
		    this.bannersp = res.data;
		    console.log(this.bannersp);
		  });
	},
		
    getrhsjlist() {
	  let language = this.$store.state.Language
	  console.log(language)
		this.$http
		  .get("/newList/list?parentId=13&language="+language)
		  .then((res) => {
			  console.log(res)
		    this.rhsjlist = res.rows;
		    console.log(this.rhsjlist);
		  })
		  .catch((err) => {});
    },
    getjsjslist() {
	  let language = this.$store.state.Language
      this.$http
        .get("/newList/list?parentId=14&language="+language)
        .then((res) => {
          this.jsjslist = res.rows;
          console.log(this.jsjslist);
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
$fontstyle: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
.gs-0 {
  width: 100%;
  font-size: 28px;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 55px;
  font-family: $fontstyle;
}
.gs-1 {
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
  padding: 5%;
  padding-left: 2.5%;
  padding-top: 0;
  padding-bottom: 0;
}

.gs-2 {
  display: block;
  width: 50%;
  float: right;
  box-sizing: border-box;
  padding: 5%;
  padding-right: 2.5%;
  padding-top: 0;
  padding-bottom: 0;
}
.gs-3 {
  margin: 10px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 22px;
  padding-left: 20px;
  border-left: 8px solid rgb(35, 80, 199);
  margin-top: 30px;
  margin-bottom: 7px;
}

.gs-4 {
  margin: 10px;
  margin-top: 7px;
  line-height: 35px;
}

.biaog {
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 60px;
  overflow: hidden;
  
  .bj11 {
    background: url(../assets/images/bj111.png) no-repeat;
    background-size: 100% 100%;
  }
  .bj111 {
    background-color: rgb(214, 225, 255);
	line-height: 18px !important;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .biaog-1 {
    float: left;
    width: 33.3%;
    height: 50px;
    // background-color: blue;s
    text-align: center;
    // line-height: 50px;
    vertical-align: middle;
    box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
  }

  .tub-1 {
    text-align: left;
	// 
	display: flex;
	justify-content: flex-start;
	align-items: center;
	
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-left: 15%;
      margin-right: 23%;
    }
  }

  .border-left {
    border-left: 1px solid rgb(232, 237, 240);
  }
  .border-right {
    border-right: 1px solid rgb(232, 237, 240);
  }
  .border-bottom {
    border-bottom: 1px solid rgb(232, 237, 240);
  }
}
.news_banner {
  // background: url(../assets/images/cptou.jpg) no-repeat;
  // background-size: 100% 100%;
  // height: 100%;
  // display: flex;
  // justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  p {
    padding-left: 100px;
  }
}

.title {
  // s

  // height: 480px;
  display: flex;
  justify-content: center;
  // width: 100%;
  p {
    padding-left: 100px;
  }
}

.rs {
  width: 100%;
  background-color: rgb(27, 23, 37);
  overflow: hidden;
}
.video{
	position: relative;
	// height: 450px;
	height: auto;
	#videoclose{
		position: absolute;
		// background-color:rgba(161, 168, 168, 0.1);
		font-size: 2.5rem;
		top: 0.625rem;
		left: 1.25rem;
	}
}
.rs2 {
  width: 100%;
  text-align: center;
  font-size: 33px;
  margin-top: 0px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 140px;
}
.rs3 {
  width: 130px;
  height: 130px;
  margin: auto;
  color: #fff;
  margin-top: 120px;
  .rs3-img{
	  width: 130px;
	  height: 130px;
	  transition: all 0.5s;
  }
  .rs3-img:hover{
	  cursor: pointer;
  }
}

.wz {
  text-align: left;
  line-height: 35px;
  position: relative;
  top: -12px;
}
@media screen and (max-width: 1024px) {
  .DHS,
  .OLYMPIC,
  .CHAMPION,
  .HURRICANE,
  .RAINBOW,
  .BADMINTON,
  .WEIGHTLIFTING,
  .BIG-BALLSPORTS,
  .DHS2 {
    display: none;
  }
  .title {
    height: auto !important;
  }
  .jianjie-msg {
    flex-direction: column !important;
    padding: 1%;
    .msg {
      width: 90%;
      padding: 0px 5%;
    }
    .img {
      width: 90%;
      padding: 0px 5%;
    }
  }
  .msg-title {
    text-align: center;
    font-size: 20px;
  }
  .msg {
    font-size: 12px !important;
  }

  .gs-1 {
    width: 100%;
    padding: 0%;
    padding-left: 0%;
  }
  .gs-2 {
    width: 100%;
    padding: 0%;
    padding-right: 0%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>

<style>
@media screen and (max-width: 1024px) {
  .center {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 50px;
  }
  .biaog {
    width: 700px !important;
    margin-bottom: 15px !important;
  }
}
</style>
